.App {
  text-align: center;
  padding: 20px;
}

body {
  margin: 0;
  padding: 0;
  background-image: url("./SummerPeonies.jpg"); /* Replace with the actual path to your image file */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: Arial, sans-serif; /* You can change the font-family if needed */
}

.container {
  max-width: 1600px; /* Set the maximum width for the container */
  margin: 0 auto; /* Center the container horizontally */
  padding: 10px;
}

.background-container {
  background-color: #ffffff; /* White background */
  border-radius: 8px; /* Optional: Add some border radius for a rounded appearance */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add a box shadow for depth */
  padding: 0px;
}

.title {
    text-align: center;
    font-family: 'Pacifico', cursive; /* Use the 'Pacifico' font from Google Fonts */
    font-size: 36px;
    margin-bottom: 20px;
    /* Gradient Background */
    background-image: linear-gradient(45deg, #ff1493, #00bfff); /* Customize the gradient colors */
    background-clip: text; /* Apply the gradient only to the text */
    -webkit-background-clip: text; /* Safari support */
    color: transparent; /* Make the text transparent to show the gradient */
    /* Underline */
    padding-bottom: 5px; /* Adjust the spacing between the text and the underline */
}

.titleLine {
  background-color: black 2px;
}

.info-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}
.clear-all-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-image: linear-gradient(45deg, #ff0000, #ff6b6b); /* Red/pink/white gradient background color */
  color: white; /* White text color */
  border: none;
  border-radius: 4px;
  padding: 2px 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.clear-all-button:hover {
  background-color: #ff4444; /* Slightly darker red on hover */
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  margin-top: 20px;
}

.sentence-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.uploaded-image {
  margin: 5px;
  padding: 5px;
  border-bottom: 2px solid black;
  text-align: center;
  position: relative;
}

.uploaded-image img {
  max-width: 120px;
  max-height: 120px;
}

.dropzone-inner {
  width: 120px;
  height: 120px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dropzone-inner p {
  margin: 0;
}

.proposal-sentence {
  font-size: 24px;
  margin-top: 20px;
}

.btn-remove {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  border-radius: 50px;
}

/* Additional CSS for the modal */
.ReactModal__Content {
  background-color: #ffffff;
  outline: none;
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.btn-primary {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}


/* Media Queries for iPhone 12 (375px width) */
@media (max-width: 375px) {
  .sentence-line {
    margin-bottom: 8px; /* Adjusted to create more space between lines */
  }

  .uploaded-image {
    margin: 2px;
    padding: 2px;
    border-bottom: 1px solid black;
  }

  .uploaded-image img {
    max-width: 50px; /* Adjusted to make images smaller */
    max-height: 70px; /* Adjusted to make images smaller */
  }

  .dropzone-inner {
    width: 50px; /* Adjusted to make dropzone smaller */
    height: 70px; /* Adjusted to make dropzone smaller */
  }

  .dropzone-inner p {
    font-size: 12px;
  }

  .proposal-sentence {
    font-size: 20px; /* Adjusted to make the sentence font smaller */
    margin-top: 10px;
  }

  button.btn-primary {
    padding: 8px 16px;
    font-size: 14px;
  }

  .btn-remove {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 7px;
  }
}

/* Media Queries for iPhone 12 Pro (390px width) */
@media (max-width: 392px) {

  .sentence-line {
    margin-bottom: 8px; /* Adjusted to create more space between lines */
  }
  
  
  .uploaded-image {
    margin: 2px;
    padding: 2px;
    border-bottom: 1px solid black;
  }

  .uploaded-image img {
    max-width: 50px; /* Adjusted to make images smaller */
    max-height: 70px; /* Adjusted to make images smaller */
  }

  .dropzone-inner {
    width: 50px; /* Adjusted to make dropzone smaller */
    height: 70px; /* Adjusted to make dropzone smaller */
  }

  .dropzone-inner p {
    font-size: 12px;
  }

  .proposal-sentence {
    font-size: 20px; /* Adjusted to make the sentence font smaller */
    margin-top: 10px;
  }

  button.btn-primary {
    padding: 8px 16px;
    font-size: 14px;
  }

  .btn-remove {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 7px;
  }
}

/* Media Queries for iPhone 12 Pro (390px width) */
@media (max-width: 440px) {

  .sentence-line {
    margin-bottom: 8px; /* Adjusted to create more space between lines */
  }

  .uploaded-image {
    margin: 2px;
    padding: 2px;
    border-bottom: 1px solid black;
  }

  .uploaded-image img {
    max-width: 50px; /* Adjusted to make images smaller */
    max-height: 70px; /* Adjusted to make images smaller */
  }

  .dropzone-inner {
    width: 50px; /* Adjusted to make dropzone smaller */
    height: 70px; /* Adjusted to make dropzone smaller */
  }

  .dropzone-inner p {
    font-size: 12px;
  }

  .proposal-sentence {
    font-size: 20px; /* Adjusted to make the sentence font smaller */
    margin-top: 10px;
  }

  button.btn-primary {
    padding: 8px 16px;
    font-size: 14px;
  }

  .btn-remove {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 7px;
  }
}

/* Media Queries for iPhone 12 Pro (390px width) */
@media (max-width: 390px) {

  .sentence-line {
    margin-bottom: 8px; /* Adjusted to create more space between lines */
  }

  .uploaded-image {
    margin: 2px;
    padding: 2px;
    border-bottom: 1px solid black;
  }

  .uploaded-image img {
    max-width: 50px; /* Adjusted to make images smaller */
    max-height: 70px; /* Adjusted to make images smaller */
  }

  .dropzone-inner {
    width: 50px; /* Adjusted to make dropzone smaller */
    height: 70px; /* Adjusted to make dropzone smaller */
  }

  .dropzone-inner p {
    font-size: 12px;
  }

  .proposal-sentence {
    font-size: 20px; /* Adjusted to make the sentence font smaller */
    margin-top: 10px;
  }

  button.btn-primary {
    padding: 8px 16px;
    font-size: 14px;
  }

  .btn-remove {
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 7px;
  }
}